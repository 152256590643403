<template>
	<div class="ybd-view">
		<ucenterHeader ></ucenterHeader>
		<div class="container">
			<ucenterLeftNav></ucenterLeftNav>
			<div class="main">
				<div class="u-rightbar">
					<div class="module-title">
						<div><i></i><span v-if="linkmanId">编辑联系人</span><span v-else>添加联系人</span></div>
					</div>
					<el-form ref="form" :model="form" :rules="rules" label-width="80px" inline>
						<el-form-item prop="name" label="姓名">
							<el-input v-model="form.name" placeholder="请输入姓名"></el-input>
						</el-form-item>
						<el-form-item prop="birthday" label="生日">
							<el-date-picker v-model="form.birthday" type="date" placeholder="选择生日" value-format="yyyy-MM-dd"></el-date-picker>
						</el-form-item>
						<el-form-item prop="company" label="公司">
							<el-input v-model="form.company" placeholder="请输入公司"></el-input>
						</el-form-item>
						<el-form-item prop="phone" label="手机">
							<el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
						</el-form-item>
						<el-form-item prop="email" label="邮箱">
							<el-input v-model="form.email" placeholder="请输入邮箱"></el-input>
						</el-form-item>
						<el-form-item prop="position" label="职位">
							<el-input v-model="form.position" placeholder="请输入职位"></el-input>
						</el-form-item>
						<el-form-item prop="sex" label="性别">
							<el-select v-model="form.sex">
								<el-option :value="0" label="男"></el-option>
								<el-option :value="1" label="女"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item prop="tel" label="固话">
							<el-input v-model="form.tel" placeholder="请输入固话"></el-input>
						</el-form-item>
					</el-form>
					<el-form ref="form" :model="form" :rules="rules" label-width="80px">
						<el-form-item label="地址">
							<el-select v-model="form.province" @change="changeProvince">
								<el-option :value="0" label="请选择"></el-option>
								<el-option v-for="item in provinceList" :key="item.adcode" :value="item.adcode" :label="item.fullname"></el-option>
							</el-select>
							-
							<el-select v-model="form.city" @change="changeCity">
								<el-option :value="0" label="请选择"></el-option>
								<el-option v-for="item in cityList" :key="item.adcode" :value="item.adcode" :label="item.fullname"></el-option>
							</el-select>
							-
							<el-select v-model="form.prefecture">
								<el-option :value="0" label="请选择"></el-option>
								<el-option v-for="item in districtList" :key="item.adcode" :value="item.adcode" :label="item.fullname"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item prop="site" label="地址">
							<el-input v-model="form.site" placeholder="请输入您的地址" type="textarea"></el-input>
						</el-form-item>
						<el-form-item prop="remark" label="备注">
							<el-input v-model="form.remark" placeholder="请输入您的备注" type="textarea"></el-input>
						</el-form-item>
						<el-form-item >
							<el-button type="primary" @click="save('form')">保存</el-button>
							<el-button @click="cancel">取消</el-button>
						</el-form-item>
					</el-form>
				</div>	
					
				
			</div>
		</div>
	</div>
</template>
<script>
import { get,post } from "/src/services/ajax_ucenter.js";
import ucenterHeader from "@/components/ucenterHeader.vue";
import ucenterLeftNav from "@/components/ucenterLeftNav.vue";
export default {
	components: {
		ucenterHeader,
		ucenterLeftNav,
		
	},
	data(){
		return {
			linkmanId:'',
			groupList:[],
			list:[],
			listParams:{
				linkmanGroup:'',
				linkmanName:'',
				pageNo:1,
				pageSize:20
			},
			count: 0,
			provinceList:[],
			cityList:[],
			districtList:[],
			form:{},
			rules:{
				name:[
					{required:true,message:'请输入联系人姓名！',trigger:'blur'}
				],
				phone:[
					{required:true,message:'请输入联系人手机号！',trigger:'blur'}
				],
				email:[
					{required:true,message:'请输入邮箱！',trigger:'blur'}
				]
			}
		}
	},
	created(){
		if(this.$route.query.linkmanId){
			this.linkmanId = this.$route.query.linkmanId;
			this.getDetail()
		}else{
			this.getProvince();
		}
	},
	computed: {
	},
	watch:{
	},
	mounted(){
		
	},
	methods: {
		// 获取联系人详情
		getDetail(){
			const _this = this;
			get('/formadmin/linkman/linkman_edit.jhtml',{linkmanId:_this.linkmanId}).then(res => {
				if(res.code == 200){
					_this.form = res.data.entity;
					_this.provinceList = res.data.province;
					if(_this.form.province){
						_this.getCity(_this.form.province);
					}
					if(_this.form.city){
						_this.getDistrict(_this.form.city);
					}
					
					
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 获取省列表
		getProvince(){
			const _this = this;
			post('/formadmin/findProvince.jhtml',{}).then(res => {
				if(res.code == 200){
					_this.provinceList = res.data;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 选择省获取市列表
		getCity(adcode){
			const _this = this;
			post('/formadmin/findCity.jhtml',{parentAdcode:adcode}).then(res => {
				if(res.code == 200){
					_this.cityList = res.data;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 选择市获取地区列表
		getDistrict(adcode){
			const _this = this;
			post('/formadmin/findDistrict.jhtml',{parentAdcode:adcode}).then(res => {
				if(res.code == 200){
					_this.districtList = res.data;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},// 选择省获取市列表
		changeProvince(adcode){
			const _this = this;
			post('/formadmin/findCity.jhtml',{parentAdcode:adcode}).then(res => {
				if(res.code == 200){
					_this.cityList = res.data;
					_this.form.city = '';
					_this.form.prefecture = '';
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 选择市获取地区列表
		changeCity(adcode){
			const _this = this;
			post('/formadmin/findDistrict.jhtml',{parentAdcode:adcode}).then(res => {
				if(res.code == 200){
					_this.districtList = res.data;
					_this.form.prefecture = '';
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 取消
		cancel(){
			const _this = this;
			_this.$router.back()
		},
		// 保存
		save(form){
			const _this = this;
			let param = {
				linkmanName:_this.form.name,
				..._this.form
			}
			let url = '';
			if(_this.linkmanId){
				url = '/formadmin/linkman/linkman_update.jhtml';
				param = {
					linkmanId:_this.form.id,
					...param
				}
			}else{
				url = '/formadmin/linkman/linkman_save.jhtml'
			}
			_this.$refs[form].validate(valid=>{
				if(valid){
					post(url,param).then(res => {
						if(res.code == 200){
							_this.$router.back()
						}else{
							_this.$message.error(res.message);
						}
					}).catch(err => {
						_this.$message.error(err);
					})
				}
			})
			
		},
		
		// 删除
		del(id){
			const _this = this;
			this.$confirm('确定删除吗？删除后不可恢复。', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				get('/formadmin/linkman/linkman_delete.jhtml',{linkmanId:id}).then(res => {
					if(res.code == 200){
						_this.getTplList();
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}).catch(() => {
				return false;
			});
		},
	}
};
</script>
<style lang="less" scoped>
.main {
	display: flex;
	flex-direction: column;
	padding: 20px;
	overflow-y: auto;
	.u-rightbar {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 20px 30px;
		background: #fff;
		.module-title{
			font-size: 16px;
			line-height: 36px;
			color:#333;
			margin-bottom: 10px;
			padding:0;
			border-bottom:none; 
			display: flex;
			justify-content: space-between;
			i{
				width: 4px;
				height:16px;
				background: var(--theme-color);
				margin-right: 10px;
				display: inline-block;
				vertical-align:-2px;
			}
		}
		.i-ico {
			width: 20px;
			height: 20px;
			cursor: pointer;
			display: inline-block;
			vertical-align: middle;
			background:url("../../../assets/images/icon_main.png") -25px -246px no-repeat;
		}
		.module-btns{
			display: flex;
			justify-content: space-between;
			align-items: center;
			.el-form--inline .el-form-item{
				margin-bottom: 0;
			}
		}
	}
	.form-table{
		.img{
			width:200px;
			height: 100px;
		}
		.i-ico{
			width:20px;
			height: 20px;
			display: inline-block;
			vertical-align: middle;
			background-image: url(../../../assets/images/ico_table.png);
		}
		.operate-btn{
			span{
				vertical-align: middle;
			}
			.i-edit{background-position: 3px 3px;}
			.i-delete{background-position: -87px 2px;}
			&:hover{
				color:var(--theme-color);
				.i-edit{background-position: 3px -27px;}
				.i-delete{background-position: -87px -28px;}
			}
		}
		.operate-btn+.operate-btn{margin-left:15px;}
	} 
	::v-deep .el-dialog{
		.el-dialog__header{
			border-bottom:1px solid #DCDFE6;
		}
		.el-dialog__body{
			overflow-y: auto;
			.el-form-item:last-child{
				margin-bottom: 0;
			}
			.avatar-uploader{
				width:100px;
				height: 100px;
				border:1px solid #DCDFE6;
					text-align: center;
				.avatar-uploader-icon {
					font-size: 28px;
					color: #8c939d;
					width:100%;
					height: 100%;
					line-height:100px;
				}
				.avatar {
					width: 100%;
					height: 100%;
					display: block;
				}
			}
		}
	} 
	::v-deep .el-pagination{
		padding: 20px 10px 10px;
		font-weight: normal;
		text-align: right;
		.el-pagination__total,
		.el-pagination__sizes{
			float: left;
			.el-input{
				.el-input__inner{
					&:hover{
						border-color: var(--theme-color);
					}
				}
			}
		}
		.el-pager{
			li:not(.disabled){
				&:hover{
					color: var(--theme-color);
				}
			}
			li:not(.disabled).active{
				background-color: var(--theme-color);
				&:hover{
					color: #fff;
				}
			}
		}
		.btn-prev,
		.btn-next{
			font-size: 12px;
			padding: 0 5px;
		}
	}	
		
}
</style>